import React, { useMemo } from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import { useTranslation } from "react-i18next";

/* ---------------- REGISTRO DE FUENTES PERSONALIZADAS ----------------- */
const FontAttributor = Quill.import("attributors/class/font");

// Definimos fuentes personalizadas en formato compatible con Quill
FontAttributor.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "times-new-roman",
];

Quill.register(FontAttributor, true);
/* --------------------------------------------------------------------- */

const Content3 = ({ emailData, handleInputChange, emisor }) => {
  const { t } = useTranslation();

  /* ---------------- MÓDULOS DE REACT QUILL (toolbar) ----------------- */
  // Configuración de los módulos de React Quill
  const modules = useMemo(
    () => ({
      toolbar: [
        [{ font: FontAttributor.whitelist }], // Ahora se cargan correctamente las fuentes
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["link", "image"], // Se mantiene la opción de insertar imágenes
        ["clean"],
      ],
    }),
    []
  );

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];
  return (
    <Box
      sx={{
        mt: 0,
        maxHeight: "80vh",
        overflowY: "auto",
        paddingRight: "16px",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t("software.cliente.stepperModalCliente.content3.emailTitle")}
      </Typography>
      <TextField
        fullWidth
        label={t("software.cliente.stepperModalCliente.content3.toLabel")}
        value={emisor} // Mostrar el valor de emisor
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label={t("software.cliente.stepperModalCliente.content3.subjectLabel")}
        value={emailData.subject || ""} // Asegúrate de que el valor sea controlado
        onChange={(e) => handleInputChange("subject")(e)} // Llamar a la función que maneja los cambios
        sx={{ mb: 2 }}
      />
      <Typography variant="subtitle1" gutterBottom>
        {t("software.cliente.stepperModalCliente.content3.messageLabel")}
      </Typography>
      <ReactQuill
        value={emailData.message || ""} // Asegúrate de que el valor sea controlado
        onChange={(value) =>
          handleInputChange("message")({ target: { value } })
        } // Capturar el contenido de ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
      />
    </Box>
  );
};

export default Content3;

import {
  DocumentEditorContainerComponent,
  Inject,
  Toolbar,
  WordExport,
} from "@syncfusion/ej2-react-documenteditor";
import { saveAs } from "file-saver"; // Importa saveAs de file-saver
import React, { useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom"; // Importar useLocation

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import styles from "./EditorCliente.module.css";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Snackbar,
} from "@mui/material";
import Fallback from "../../../assets/Animations/SafeConFallback";
import "../../../assets/styles/Syncfussion/editor.css";
import AnexosModal from "./AnexosModal";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Bs1Circle, Bs2Circle, Bs3Circle } from "react-icons/bs";
import Joyride from "react-joyride";
import video3 from "../../../assets/Animations/Tutorial-Comentarios.mp4";
import video1 from "../../../assets/Animations/Tutorial-Importar.mp4";
import video2 from "../../../assets/Animations/Tutorial-Principal.mp4";
import foto1 from "../../../assets/images/LogoDef.webp";
import "../../../assets/styles/Syncfussion/grid.css";
import NotFound from "../../Signup/NotFound";
import HelpModal from "./HelpModal";
import StepperController from "./StepperController";

import { L10n } from "@syncfusion/ej2-base";
import data from "../../../languages/editor/editor-ES.json";
import { Button as ChakraButton } from "@chakra-ui/react";

const API_SYNCFUSSION_URL = process.env.REACT_APP_SYNCFUSSION_BASE_URL;

/*
const LEER_VERSION = gql`
  query LeerVersion(
    $documentKey: String!
    $versionId: String!
    $encryptedPin: String!
    $company: String!
    $isClient: Boolean!
  ) {
    leerVersion(
      documentKey: $documentKey
      versionId: $versionId
      encryptedPin: $encryptedPin
      company: $company
      isClient: $isClient
    ) {
      content
    }
  }
`;

*/

const LEER_VERSION = gql`
  query LeerVersionCliente(
    $documentKey: String!
    $versionId: String!
    $sfdtKeyEditable: String!
    $encryptedPin: String!
    $company: String!
    $isClient: Boolean!
  ) {
    leerVersionCliente(
      documentKey: $documentKey
      versionId: $versionId
      sfdtKeyEditable: $sfdtKeyEditable
      encryptedPin: $encryptedPin
      company: $company
      isClient: $isClient
    ) {
      content
    }
  }
`;

//Define aquí aquí la mutación de registrarVersion

const REGISTRAR_VERSION = gql`
  mutation RegistrarVersion(
    $sfdtFile: Upload!
    $docxFile: Upload!
    $documentKey: String!
    $docxDocumentKey: String!
    $userEmail: String!
    $encryptedPin: String!
    $company: String!
    $description: String!
    $aliasVersion: String!
    $notificationType: String!
  ) {
    registrarVersion(
      sfdtFile: $sfdtFile
      docxFile: $docxFile
      documentKey: $documentKey
      docxDocumentKey: $docxDocumentKey
      userEmail: $userEmail
      encryptedPin: $encryptedPin
      company: $company
      description: $description
      aliasVersion: $aliasVersion
      notificationType: $notificationType
    ) {
      success
      message
      versionIdSfdt
      versionIdPdf
    }
  }
`;

// Define the GraphQL query to get the document title
const DATOS_DOCUMENTO = gql`
  query DatosDocumentocompartido($documentKey: String!, $userEmail: String!) {
    datosDocumentocompartido(documentKey: $documentKey, userEmail: $userEmail) {
      titulo
      currentUserAprobador
      currentUserAprobado
      todosAprobados
      estado
    }
  }
`;

const RESPUESTA_VERSION = gql`
  mutation RespuestaVersion(
    $documentKey: String!
    $versionIdSfdt: String!
    $anexos: [String!]
    $destinatario: String!
    $subject: String!
    $message: String!
    $emisor: String!
    $titulo: String!
    $tokenRespuesta: String!
    $conformidad: Boolean!
    $encryptedPin: String!
  ) {
    respuestaVersion(
      documentKey: $documentKey
      versionIdSfdt: $versionIdSfdt
      anexos: $anexos
      destinatario: $destinatario
      subject: $subject
      message: $message
      emisor: $emisor
      titulo: $titulo
      tokenRespuesta: $tokenRespuesta
      conformidad: $conformidad
      encryptedPin: $encryptedPin
    ) {
      success
      message
    }
  }
`;

const CONVERT_DOCX_TO_PDF = gql`
  mutation ConvertDocxToPdf($file: Upload!) {
    convertDocxToPdf(file: $file) {
      success
      pdfFile
      error
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexos($documentKey: String!) {
    obtenerAnexos(documentKey: $documentKey) {
      titulo
      clave
    }
  }
`;

const VERIFICAR_TOKEN = gql`
  query VerificarToken($documentKey: String!, $tokenRespuesta: String!) {
    verificarToken(documentKey: $documentKey, tokenRespuesta: $tokenRespuesta) {
      existe
    }
  }
`;

const CONVERT_PDF_TO_DOCX = gql`
  mutation ConvertPdfToDocx($file: Upload!) {
    convertPdfToDocx(file: $file) {
      success
      docxFile
      message
    }
  }
`;

const REGISTER_DOWNLOAD = gql`
  mutation RegisterDownload(
    $documentKey: String!
    $email: String!
    $versionId: String!
  ) {
    registerDownload(
      documentKey: $documentKey
      email: $email
      versionId: $versionId
    ) {
      status {
        success
        message
      }
      f
    }
  }
`;

const GUARDAR_CAMBIOS = gql`
  mutation GuardarCambios(
    $documentKey: String!
    $sfdtKeyEditable: String!
    $encryptedPin: String!
    $company: String!
    $sfdtFile: String!
  ) {
    guardarCambios(
      documentKey: $documentKey
      sfdtKeyEditable: $sfdtKeyEditable
      encryptedPin: $encryptedPin
      company: $company
      sfdtFile: $sfdtFile
    ) {
      success
      message
    }
  }
`;

function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  const sliceSize = 512;
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

//Editor componente de syncfussion
const MyEditor = ({
  onRefReady,
  documentContent,
  usuario,
  titulo,
  documentkey,
  uploadDocument,
  showMessage,
  setShowMessage,
  operationSuccess,
  setOperationSuccess,
  setShowAnexosmodal,
  anexosnumber,
  docxDocumentKey,
  idVersion,
  emisor,
  token,
  encryptedPin,
  company,
  alias,
  allowDownload,
  sfdtKeyEditable,
}) => {
  const { t, i18n } = useTranslation();
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isDocumentModified, setIsDocumentModified] = useState(false);
  const encodedDocumentKey = encodeURIComponent(documentkey);
  const [showDialog, setShowDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false); // Estado para manejar el modal de descarga
  const [showRegisterModal, setShowRegisterModal] = useState(false); // Nuevo estado para el modal de registro
  const [showStepperModal, setShowStepperModal] = useState(false);
  const [comments, setComments] = useState([]); //Comentarios total que se pasan al stepper
  const [isImporting, setIsImporting] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1265);
  const [runTour, setRunTour] = useState(false);

  const [currentDocumentSfdt, setCurrentDocumentSfdt] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1265);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (
    i18n.language ===
    "qjlkdjfañlfkdjlñsadjfñaljfñasdfjkñaldkjfñalkdfjñskldfjñlkdfjñlsdajf"
  ) {
    console.log(comments);
  }
  if (i18n.language === "es") {
    L10n.load(data);
  }

  const saveCurrentDocumentAsSfdt = async () => {
    if (editorRef.current) {
      const blob = await editorRef.current.documentEditor.saveAsBlob("Sfdt");
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = () => {
          const base64 = reader.result.split(",")[1];
          const sfdtContent = JSON.parse(atob(base64));
          resolve(sfdtContent);
        };
        reader.readAsDataURL(blob);
      });
    }
    return null;
  };

  const reopenDocument = (sfdtContent) => {
    if (editorRef.current && sfdtContent) {
      editorRef.current.documentEditor.open(sfdtContent);
    }
  };

  const handleLanguageChange = async (newLang) => {
    // Guardar el contenido actual
    const sfdtContent = await saveCurrentDocumentAsSfdt();
    if (sfdtContent) {
      setCurrentDocumentSfdt(sfdtContent);
    }
    // Cambiar el idioma
    i18n.changeLanguage(newLang);
  };

  // Cada vez que cambie el idioma, re-abrir el documento si existe
  useEffect(() => {
    if (editorRef.current && currentDocumentSfdt) {
      reopenDocument(currentDocumentSfdt);
    }
  }, [i18n.language, currentDocumentSfdt]);

  const navigate = useNavigate();

  // Utilizar la mutación RegistrarVersion
  const [registrarVersion] = useMutation(REGISTRAR_VERSION);

  const [convertPdfToDocxMutation] = useMutation(CONVERT_PDF_TO_DOCX);

  // Mutacion guardar cambios

  const [guardarCambios] = useMutation(GUARDAR_CAMBIOS);

  const handleDownloadClick = () => {
    setOpenDownloadModal(true); // Abre el modal de descarga
  };

  const handleDownloadModalClose = () => {
    setOpenDownloadModal(false); // Cierra el modal de descarga
  };

  const handleOpenHelpModal = () => {
    setHelpModalOpen(true);
  };

  const handleCloseHelpModal = () => {
    setHelpModalOpen(false);
  };
  // Bloquear la navegación cuando hay cambios sin guardar
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      isDocumentModified && currentLocation.pathname !== nextLocation.pathname
    );
  });

  useEffect(() => {
    if (blocker.state === "blocked") {
      setShowDialog(true);
    }
  }, [blocker.state]);

  const repeatTutorial = () => {
    // Borramos el flag en localStorage
    localStorage.removeItem("tutorialCompleted");
    // Y forzamos a que se vuelva a lanzar
    setHelpModalOpen(false);
    setRunTour(true);
  };

  useEffect(() => {
    const tutorialCompleted = localStorage.getItem("tutorialCompleted");
    if (tutorialCompleted === "true") {
      setRunTour(false);
    } else {
      setRunTour(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      localStorage.setItem("tutorialCompleted", "true");
      setRunTour(false);
    }
  };

  const handleSaveAndProceed = async () => {
    setIsSaving(true);
    await handleSaveDraft();
    setIsSaving(false);
    blocker.proceed();
    setShowDialog(false);
  };

  const handleProceedWithoutSaving = () => {
    blocker.proceed();
    setShowDialog(false);
  };

  const handleCancelNavigation = () => {
    blocker.reset();
    setShowDialog(false);
  };

  // Guardar cambios

  const handleSaveChanges = async () => {
    if (editorRef.current) {
      const documentEditor = editorRef.current.documentEditor;

      // Obtener el contenido en formato Sfdt
      const blob = await documentEditor.saveAsBlob("Sfdt");
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64File = reader.result.split(",")[1]; // Obtener base64 sin el encabezado

        try {
          const response = await guardarCambios({
            variables: {
              documentKey: documentkey,
              sfdtKeyEditable: sfdtKeyEditable,
              encryptedPin: encryptedPin,
              company: company,
              sfdtFile: base64File,
            },
          });

          if (response.data.guardarCambios.success) {
            setOperationSuccess(true);
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 3000); // Ocultar después de 3s
          } else {
            console.error(
              "Error al guardar:",
              response.data.guardarCambios.message
            );
          }
        } catch (error) {
          console.error("Error en la mutación:", error);
        }
      };

      reader.readAsDataURL(blob);
    }
  };

  // Manejar el cierre del navegador
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDocumentModified) {
        event.preventDefault();
        event.returnValue =
          "Tienes cambios sin guardar. ¿Estás seguro de que deseas salir?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDocumentModified]);

  const [registerDownload] = useMutation(REGISTER_DOWNLOAD);

  const downloadDocumentAsDocx = async () => {
    handleDownloadModalClose();
    if (editorRef.current) {
      const documentEditor = editorRef.current.documentEditor;
      documentEditor.saveAsBlob("Docx").then(async (blob) => {
        saveAs(blob, "Documento.docx");

        // Registrar la descarga
        try {
          const response = await registerDownload({
            variables: {
              documentKey: documentkey,
              email: usuario,
              versionId: idVersion,
            },
          });

          if (response.data.registerDownload.status.success) {
            console.log(
              "Registro de descarga exitoso:",
              response.data.registerDownload.status.message
            );
          } else {
            console.error(
              "Error al registrar la descarga:",
              response.data.registerDownload.status.message
            );
          }
        } catch (error) {
          console.error(
            "Error al ejecutar la mutación REGISTER_DOWNLOAD:",
            error
          );
        }
      });
    }
  };

  const handleSaveDraft = async (notificationType) => {
    try {
      if (editorRef.current) {
        setIsSaving(true);

        const documentEditor = editorRef.current.documentEditor;

        // Guardar como SFDT
        const sfdtBlob = await documentEditor.saveAsBlob("Sfdt");
        const sfdtFile = new File([sfdtBlob], "Documento.sfdt", {
          type: "application/json",
        });

        // Eliminar comentarios antes de guardar como DOCX
        documentEditor.editor.deleteAllComments();

        // Guardar como DOCX
        const docxBlob = await documentEditor.saveAsBlob("Docx");
        const docxFile = new File([docxBlob], "Documento.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Enviar ambos archivos al backend
        const response = await registrarVersion({
          variables: {
            sfdtFile,
            docxFile,
            documentKey: documentkey,
            docxDocumentKey,
            userEmail: usuario,
            encryptedPin: encryptedPin,
            company: company,
            aliasVersion: alias,
            description: `Registro de respuesta de ${alias} `,
            notificationType,
          },
        });

        if (response.data.registrarVersion.success) {
          setOperationSuccess(true);
          setShowMessage(true);
          setIsDocumentModified(false); // Actualiza el estado antes de la navegación

          // Navega a la nueva ruta después de un pequeño retraso para asegurar que el estado se actualice primero
          setTimeout(() => {
            navigate(
              `/dashboard/shared-documents/versions/${encodedDocumentKey}`,
              { replace: true }
            );
          }, 200); // Ajusta el tiempo si es necesario

          setTimeout(() => setShowMessage(false), 500);
        } else {
          throw new Error("Error al guardar el documento");
        }
      }
    } catch (error) {
      console.error("Error al actualizar el documento", error);
      setOperationSuccess(false);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 500);
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnSendSaveDraft = async () => {
    try {
      if (editorRef.current) {
        setIsSaving(true);

        const documentEditor = editorRef.current.documentEditor;

        // Guardar como SFDT
        const sfdtBlob = await documentEditor.saveAsBlob("Sfdt");
        const sfdtFile = new File([sfdtBlob], "Documento.sfdt", {
          type: "application/json",
        });

        // Eliminar comentarios antes de guardar como DOCX
        documentEditor.editor.deleteAllComments();

        // Guardar como DOCX
        const docxBlob = await documentEditor.saveAsBlob("Docx");
        const docxFile = new File([docxBlob], "Documento.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Enviar ambos archivos al backend
        const response = await registrarVersion({
          variables: {
            sfdtFile,
            docxFile,
            documentKey: documentkey,
            docxDocumentKey,
            userEmail: usuario,
            encryptedPin: encryptedPin,
            company: company,
            aliasVersion: alias,
            description: `Registro de envío de ${alias} `,
            notificationType: "newversionrespuesta",
          },
        });

        if (response.data.registrarVersion.success) {
          setOperationSuccess(true);
          setShowMessage(true);
          setIsDocumentModified(false); // Actualiza el estado antes de la navegación

          // Retornar el versionIdSfdt
          return response.data.registrarVersion.versionIdSfdt;
        } else {
          throw new Error("Error al guardar el documento");
        }
      }
    } catch (error) {
      console.error("Error al actualizar el documento", error);
      setOperationSuccess(false);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 500);
    } finally {
      setIsSaving(false);
    }
  };

  const openAnexosModal = () => {
    console.log("documentKey:", documentkey); // Añade esta línea para verificar el valor
    if (!documentkey) {
      alert("Clave del documento no está disponible.");
      return;
    }
    setShowAnexosmodal(true);
  };

  // Function to handle file upload
  const handleFileImport = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (event) => {
    setIsImporting(true); // Inicia el indicador de carga
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        // Si es un PDF, usar la mutación de GraphQL para convertirlo a DOCX
        convertPdfToDocxMutation({ variables: { file } })
          .then((response) => {
            if (response.data.convertPdfToDocx.success) {
              const docxBase64 = response.data.convertPdfToDocx.docxFile;
              // Convertir base64 a Blob
              const docxBlob = base64ToBlob(
                docxBase64,
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              );
              const docxFile = new File([docxBlob], "converted.docx");
              const formData = new FormData();
              formData.append("files", docxFile);
              // Enviar el archivo DOCX al endpoint existente
              fetch(`${API_SYNCFUSSION_URL}/api/documenteditor/Import`, {
                method: "POST",
                body: formData,
              })
                .then((response) => response.json())
                .then((data) => {
                  if (editorRef.current) {
                    editorRef.current.documentEditor.open(data);
                    setIsDocumentModified(true);
                    setIsImporting(false); // Oculta el indicador de carga
                  }
                })
                .catch((error) => {
                  console.error("Error:", error);
                  setIsImporting(false); // Oculta el indicador de carga en caso de error
                });
            } else {
              console.error(
                "Error converting PDF to DOCX:",
                response.data.convertPdfToDocx.message
              );
              setIsImporting(false); // Oculta el indicador de carga en caso de error
            }
          })
          .catch((error) => {
            console.error("GraphQL error:", error);
            setIsImporting(false); // Oculta el indicador de carga en caso de error
          });
      } else if (fileExtension === "doc" || fileExtension === "docx") {
        // Si es DOC o DOCX, continuar con el proceso existente
        const formData = new FormData();
        formData.append("files", file);

        fetch(`${API_SYNCFUSSION_URL}/api/documenteditor/Import`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (editorRef.current) {
              editorRef.current.documentEditor.open(data);
              setIsDocumentModified(true);
              setIsImporting(false); // Oculta el indicador de carga
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsImporting(false); // Oculta el indicador de carga en caso de error
          });
      } else {
        alert(
          "Formato de archivo no soportado. Por favor, selecciona un archivo PDF, DOC o DOCX."
        );
        setIsImporting(false); // Oculta el indicador de carga en caso de error
      }
    } else {
      setIsImporting(false); // Oculta el indicador de carga si no hay archivo
    }
  };

  //Conversion a pdf
  const [convertDocxToPdf] = useMutation(CONVERT_DOCX_TO_PDF);

  const downloadDocumentAsPdf = async () => {
    if (editorRef.current) {
      const documentEditor = editorRef.current.documentEditor;

      // Guardar como DOCX
      documentEditor.saveAsBlob("Docx").then((docxBlob) => {
        const docxFile = new Blob([docxBlob], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const docxFileToSend = new File([docxFile], "Documento.docx");

        convertDocxToPdf({
          variables: { file: docxFileToSend },
        })
          .then(async (response) => {
            if (response.data.convertDocxToPdf.success) {
              const pdfBase64 = response.data.convertDocxToPdf.pdfFile;

              // Crear un enlace dinámico para descargar el PDF
              const pdfLink = `data:application/pdf;base64,${pdfBase64}`;
              const anchorElement = document.createElement("a");
              anchorElement.href = pdfLink;
              anchorElement.download = "Documento.pdf";
              document.body.appendChild(anchorElement);
              anchorElement.click();
              document.body.removeChild(anchorElement);

              // Registrar la descarga
              try {
                const registerResponse = await registerDownload({
                  variables: {
                    documentKey: documentkey,
                    email: usuario,
                    versionId: idVersion,
                  },
                });

                if (registerResponse.data.registerDownload.status.success) {
                  console.log(
                    "Registro de descarga exitoso:",
                    registerResponse.data.registerDownload.status.message
                  );
                } else {
                  console.error(
                    "Error al registrar la descarga:",
                    registerResponse.data.registerDownload.status.message
                  );
                }
              } catch (error) {
                console.error(
                  "Error al ejecutar la mutación REGISTER_DOWNLOAD:",
                  error
                );
              }
            } else {
              console.error(
                "Error converting DOCX to PDF:",
                response.data.convertDocxToPdf.error
              );
            }
          })
          .catch((error) => {
            console.error("Error during mutation:", error);
          });
      });
    }
  };

  const confirmRegisterVersion = async () => {
    setShowRegisterModal(false);
    const notificationType = "newversioninterna";
    await handleSaveDraft(notificationType);
  };

  const cancelRegisterVersion = () => {
    setShowRegisterModal(false);
  };

  //Modal Stepper
  const handleOpenModal = () => {
    if (editorRef.current) {
      const editor = editorRef.current.documentEditor;

      // Forzar una actualización de los comentarios antes de abrir el modal
      editor.editorModule.isContentChanged = true; // Marca el contenido como cambiado para forzar la actualización
      editor.editorModule.layoutWholeDocument(); // Asegura que el documento se vuelva a procesar

      // Obtener todos los comentarios actualizados antes de abrir el modal
      const updatedComments = editor.getComments();

      // Actualizar los comentarios primero
      setComments(updatedComments);

      // Usar setTimeout para esperar que el estado de comentarios se actualice antes de abrir el modal
      setTimeout(() => {
        setShowStepperModal(true);
      }, 0); // Asegurar que React procese el estado antes de abrir el modal
    }
  };

  // Asegurarse de que los comentarios se actualizan cada vez que cambien
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.documentEditor;

      const handleCommentAdded = () => {
        // Forzar la actualización de todo el documento cuando se agrega un comentario
        editor.editorModule.isContentChanged = true;
        editor.editorModule.layoutWholeDocument();

        const currentComments = editor.getComments();
        setComments(currentComments); // Actualiza el estado con los comentarios actuales
        console.log("Comentario añadido:", currentComments);
      };

      // Listener para comentarios añadidos y eliminados
      editor.commentAdded = handleCommentAdded;
      editor.commentDeleted = handleCommentAdded;

      return () => {
        // Limpiar los listeners cuando se desmonte el componente
        editor.commentAdded = null;
        editor.commentDeleted = null;
      };
    }
  }, [editorRef]);

  const handleCloseModal = () => {
    setShowStepperModal(false);
  };

  const customToolbarItems = [
    {
      prefixIcon: "custom-logo-button", // Clase para el ícono
      cssClass: "custom-logo-toolbar-btn tour-logo", // Clase personalizada para el botón completo
      align: "Left",
      click: () => {
        window.location.href = "https://safecon.app";
      },
    },

    "Separator",
    {
      prefixIcon: "e-de-ctnr-comments e-icons", // Clase de icono de Syncfusion
      tooltipText: titulo, // El texto del tooltip será el título del contrato
      text: `${titulo}`, // El texto que se mostrará en el toolbar
      cssClass: "custom-title-button",
      align: "Left",
    },
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    "Image",
    "Separator",
    "Table",
    "Separator",
    "TrackChanges",

    "Separator",
    "Find",
    "Separator",
    "Comments",
    "Separator",

    allowDownload && {
      prefixIcon: "e-icons e-download", // Clase de icono de exportación de Syncfusion
      tooltipText: t("software.editor.downloadButton"), // Tooltip para el botón de descarga
      text: t("software.editor.downloadButton"),
      align: "Left",
      click: handleDownloadClick, // Función que se ejecutará al hacer clic
      cssClass: "tour-download",
    },
    /*
    {
      prefixIcon: "e-icons e-download", // Clase de icono de exportación de Syncfusion
      tooltipText: t("software.editor.downloadButton"), // Tooltip para el botón de descarga
      text: t("software.editor.downloadButton"),
      align: "Left",
      click: handleDownloadClick, // Función que se ejecutará al hacer clic
      cssClass: "tour-download",
    },
    */
    "Separator",
    {
      prefixIcon: "e-icons e-upload-1",
      tooltipText: t("software.editor.importTooltip"),
      text: t("software.editor.importButton"),
      align: "Left",
      click: handleFileImport, // Maneja la carga del archivo
      cssClass: "tour-import",
    },
    "Separator",
    {
      prefixIcon: "e-icons e-folder", // Clase de icono de guardado de Syncfusion
      tooltipText: t("software.editor.attachedTooltip"), // Tooltip para el botón de guardado
      text: t("software.editor.attachedButton", { number: anexosnumber }),
      align: "Left",
      click: openAnexosModal, // Función que se ejecutará al hacer clic
      cssClass: "tour-anexos",
    },
    "Separator",
    {
      prefixIcon: "e-icons e-save",
      tooltipText: t("software.editor.saveButton"),
      text: t("software.editor.saveButton"),
      cssClass: "custom-save-button",
      align: "Left",
      click: handleSaveChanges,
    },
    "Separator",
    {
      prefixIcon: "e-icons e-circle-info", // Clase de icono de guardado de Syncfusion
      tooltipText: t("software.editor.helpButton"), // Tooltip para el botón de guardado
      text: t("software.editor.helpButton"),
      align: "Left",
      click: handleOpenHelpModal, // Función que se ejecutará al hacer clic
      cssClass: "tour-help",
    },
    "Separator",
    {
      prefixIcon: "e-icons e-send custom-send-icon",
      // Clase de icono de guardado de Syncfusion
      tooltipText: t("software.editor.answer"), // Tooltip para el botón de guardado
      text: isSmallScreen ? "" : t("software.editor.answer"),
      cssClass: isSmallScreen
        ? "custom-continuar-button small tour-responder"
        : "custom-continuar-button tour-responder",
      align: "Right",
      click: handleOpenModal, // Función que se ejecutará al hacer clic
      attributes: { "data-tour": "responder" },
    },
  ].filter(Boolean);

  const convertClipboardContentToText = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      let text = "";

      for (const item of clipboardItems) {
        if (item.types.includes("text/html")) {
          const blob = await item.getType("text/html");
          const html = await blob.text();
          // Convertir HTML a texto plano
          const doc = new DOMParser().parseFromString(html, "text/html");
          text = doc.body.innerText;

          // Escribir el texto convertido de vuelta en el portapapeles
          await navigator.clipboard.writeText(text);
        } else if (item.types.includes("text/plain")) {
          const blob = await item.getType("text/plain");
          text = await blob.text();
        }
      }

      //console.log("Clipboard content converted to text and updated:", text);
    } catch (error) {
      //console.error("Error converting clipboard content:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      convertClipboardContentToText();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Si no existe la referencia, sal
    if (!editorRef.current) return;

    const editorContainer = editorRef.current;
    const editor = editorContainer.documentEditor;
    if (!editor) return;

    // Config. inicial
    editor.zoomFactor = 1;
    editor.enableLocalPaste = false;
    editor.ShowComments = false;
    editor.currentUser = usuario;
    editor.userColor = "#FF4081";
    editor.serviceUrl = `${API_SYNCFUSSION_URL}/api/documenteditor`;

    // Abrir si hay contenido
    if (documentContent) {
      editor.open(documentContent);
    }

    // Manejar cambios
    editor.contentChange = () => setIsDocumentModified(true);

    // Redefinir keyDown
    editor.keyDown = (args) => {
      if (!args || !args.event) return;
      const isCtrl = args.event.ctrlKey || args.event.metaKey;
      const key = args.event.key.toLowerCase();
      // Bloquear Ctrl+S y Ctrl+O
      if (isCtrl && (key === "s" || key === "o")) {
        args.isHandled = true;
        args.event.preventDefault();
        console.log(`Bloqueado: Ctrl+${key.toUpperCase()}`);
      }
    };

    // Limpieza
    return () => {
      // Revisar si editorContainer está destruido
      if (editorContainer && editorContainer.documentEditor) {
        // Solo si el editor existe aún
        const ed = editorContainer.documentEditor;
        ed.keyDown = null;
        ed.contentChange = null;
      }
    };
  }, [editorRef, documentContent, usuario]);

  useEffect(() => {
    // Bloquear Ctrl + S y Ctrl + O a nivel de navegador
    const handleKeyDown = (event) => {
      const isCtrl = event.ctrlKey || event.metaKey;
      const key = event.key.toLowerCase();

      if (isCtrl && (key === "s" || key === "o")) {
        event.preventDefault();
        console.log(
          `Bloqueado a nivel de navegador: Ctrl+${key.toUpperCase()}`
        );
      }
    };

    // Agregar el evento global
    window.addEventListener("keydown", handleKeyDown);

    // Limpiar el evento al desmontar
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Funcion de envío en la que se ejecuta la mutacion de envío  se registra la nueva versión enviada
  //Tras la mutación de envío se ejecuta la función de guarddado

  useEffect(() => {
    if (editorRef.current) {
      setTimeout(() => {
        const propertyPaneButton = document.querySelector(".e-de-showhide-btn");
        if (propertyPaneButton) {
          propertyPaneButton.setAttribute("data-tour", "toggle-property-pane");
        }
      }, 0);
    }
  }, [editorRef]);

  const [respuestaVersion] = useMutation(RESPUESTA_VERSION);

  const handleSend = async (metaData) => {
    try {
      if (editorRef.current) {
        const versionIdSfdt = await handleOnSendSaveDraft();

        if (versionIdSfdt) {
          const anexos = metaData.step2.anexos;
          const emailData = metaData.step3;
          const conformidad = metaData.step1; // Ya es un booleano (`true` o `false`)

          const response = await respuestaVersion({
            variables: {
              documentKey: documentkey,
              versionIdSfdt: versionIdSfdt,
              anexos: anexos,
              destinatario: emailData.to,
              subject: emailData.subject,
              message: emailData.message,
              emisor: usuario,
              conformidad, // Enviar directamente el valor booleano
              titulo,
              tokenRespuesta: token,
              encryptedPin: encryptedPin,
            },
          });

          if (response.data.respuestaVersion.success) {
            console.log("Documento enviado con éxito");

            // Redirigir a la página de éxito y pasar el destinatario usando state
            navigate("/respuesta-enviada", {
              state: { destinatario: emailData.to }, // Pasar destinatario como variable en `state`
            });
          }
        }
      }
    } catch (error) {
      console.error("Error en el proceso de envío:", error);
    }
  };

  function CustomDropdown({ value, onChange, options }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleOptionClick = (optionValue) => {
      onChange(optionValue);
      setIsOpen(false);
    };

    // Cerrar el dropdown si se hace clic fuera de él
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    const selectedOption = options.find((opt) => opt.value === value);

    return (
      <div
        ref={dropdownRef}
        style={{
          position: "relative",
          fontFamily: "sans-serif",
          fontSize: "14px",
        }}
      >
        <div
          onClick={handleToggle}
          style={{
            backgroundColor: "#fff",
            border: "none",
            padding: "8px 12px",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "100px",
          }}
        >
          <span>{selectedOption ? selectedOption.label : "Select..."}</span>
          <span
            style={{
              display: "inline-block",
              width: "0",
              height: "0",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: isOpen ? "none" : "5px solid #333",
              borderBottom: isOpen ? "5px solid #333" : "none",
              marginLeft: "8px",
            }}
          />
        </div>
        {isOpen && (
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: "8px 0",
              position: "absolute",
              top: "calc(100% + 5px)",
              left: 0,
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              zIndex: 999,
            }}
          >
            {options.map((opt) => (
              <li
                key={opt.value}
                onClick={() => handleOptionClick(opt.value)}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  transition: "background 0.2s ease-in-out",
                  ...(opt.value === value
                    ? { backgroundColor: "#f0f0f0", fontWeight: "bold" }
                    : {}),
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f7f7f7")
                }
                onMouseLeave={(e) => {
                  if (opt.value === value) {
                    e.currentTarget.style.backgroundColor = "#f0f0f0";
                  } else {
                    e.currentTarget.style.backgroundColor = "#fff";
                  }
                }}
              >
                {opt.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  const options = [
    { value: "es", label: t("language.spanish") },
    { value: "en", label: t("language.english") },
  ];

  const steps = [
    {
      target: "body",
      content: (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            maxWidth: "700px",
            margin: "0 auto",
            height: "74vh", // Ajustar la altura en función del viewport
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centrar verticalmente
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative", // Hacer posible posicionar elementos dentro del contenedor
          }}
        >
          {/* Aquí añadimos el selector de idioma en la esquina superior derecha */}
          <div style={{ position: "absolute", top: "10px", right: "10px" }}>
            <CustomDropdown
              value={i18n.language}
              onChange={(lang) => handleLanguageChange(lang)}
              options={options}
            />
          </div>

          <img
            src={foto1}
            alt={t("software.cliente.onboarding.imageDescription")}
            style={{
              maxWidth: "30%",
              maxHeight: "30vh", // Limitar la altura al 30% de la pantalla
              objectFit: "contain", // Asegurarse de que la imagen se ajuste sin deformarse
              marginBottom: "10px",
            }}
          />

          {/* Pasos del usuario */}
          <div
            style={{
              textAlign: "left",
              width: "90%",
              marginBottom: "10px",
              padding: "0 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <Bs1Circle
                style={{
                  marginRight: "10px",
                  fontSize: "1.2rem",
                  flexShrink: 0, // Asegura que el icono no se reduzca
                }}
              />
              <p
                style={{ fontSize: "1.1rem", margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: t("software.cliente.onboarding.step1"),
                }}
              ></p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <Bs2Circle
                style={{
                  marginRight: "10px",
                  fontSize: "1.2rem",
                  flexShrink: 0,
                }}
              />
              <p
                style={{ fontSize: "1.1rem", margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: t("software.cliente.onboarding.step2"),
                }}
              ></p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <Bs3Circle
                style={{
                  marginRight: "10px",
                  fontSize: "1.2rem",
                  flexShrink: 0,
                }}
              />
              <p
                style={{ fontSize: "1.1rem", margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: t("software.cliente.onboarding.step3"),
                }}
              ></p>
            </div>
          </div>

          {/* Video añadido */}
          <video
            src={video2}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "90%",
              maxWidth: "600px",
              maxHeight: "40vh", // Limitar la altura del video al 40% del viewport
              objectFit: "contain", // Mantener la relación de aspecto
              borderRadius: "20px",
              marginTop: "20px",
            }}
            ref={(video) => {
              if (video) video.playbackRate = 1.3; // Ajusta la velocidad de reproducción
            }}
          />
        </div>
      ),
      placement: "center",
      styles: {
        options: {
          width: "auto",
          maxWidth: "750px",
        },
      },
      disableBeacon: true,
      media: `
        @media (max-width: 1024px) {
          div {
            height: 70vh; /* Reducir la altura en pantallas más pequeñas */
          }
          h3 {
            font-size: 1.1rem;
          }
          img {
            max-width: 50%;
            maxHeight: 20vh; /* Limitar altura en pantallas pequeñas */
          }
          video {
            max-width: 80%;
            max-height: 30vh; /* Reducir la altura del video en pantallas pequeñas */
          }
          p {
            font-size: 1rem;
          }
        }
        @media (max-width: 768px) {
          div {
            height: 60vh;
          }
          h3 {
            font-size: 1.2rem;
          }
          img {
            max-width: 60%;
            maxHeight: 15vh; /* Reducir la altura de la imagen en pantallas más pequeñas */
          }
          video {
            max-width: 90%;
            max-height: 25vh;
          }
          p {
            font-size: 0.9rem;
          }
        }
        @media (max-width: 480px) {
          div {
            height: 50vh;
          }
          h3 {
            font-size: 1rem;
          }
          img {
            max-width: 70%;
            maxHeight: 10vh;
          }
          video {
            max-width: 100%;
            max-height: 20vh;
          }
          p {
            font-size: 0.8rem;
          }
        }
      `,
    },
    {
      target: '[data-tour="toggle-property-pane"]',
      disableBeacon: true,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t("software.dashboard.onboarding.editor.steps.7.content"),
          }}
        />
      ),
      placement: "bottom",
    },
    {
      target: 'button[id$="_toolbar_comment"]',
      content: (
        <div
          style={{
            textAlign: "center",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          <h3
            dangerouslySetInnerHTML={{
              __html: t("software.cliente.onboarding.commentSectionTitle"),
            }}
          ></h3>
          <video
            src={video3}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              maxHeight: "400px",
              borderRadius: "12px",
              marginTop: "20px",
            }}
          />
        </div>
      ),
      placement: "bottom",
      styles: {
        options: {
          width: "auto",
          maxWidth: "750px",
          marginTop: "10px",
          marginBottom: "10px",
        },
      },
      media: `
      @media (max-width: 1024px) {
        div {
          height: 70vh;
          paddingTop: "15px";
          paddingBottom: "15px";
        }
        video {
          max-width: 80%;
          max-height: 30vh;
        }
      }
      @media (max-width: 768px) {
        div {
          height: 60vh;
          paddingTop: "10px";
          paddingBottom: "10px";
        }
        video {
          max-width: 90%;
          max-height: 25vh;
        }
      }
      @media (max-width: 480px) {
        div {
          height: 50vh;
          paddingTop: "5px";
          paddingBottom: "5px";
        }
        video {
          max-width: 100%;
          max-height: 20vh;
        }
      }
    `,
    },
    {
      target: ".tour-download",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t("software.cliente.onboarding.downloadSection"),
          }}
        ></div>
      ),
    },
    {
      target: ".tour-import", // Asegúrate de que este selector apunte correctamente al botón
      content: (
        <div
          style={{
            textAlign: "center",
            maxWidth: "500px", // Limitar el ancho máximo del contenido
            margin: "20px auto",
            marginLeft: "20px",
          }}
        >
          <h3
            dangerouslySetInnerHTML={{
              __html: t("software.cliente.onboarding.importSectionTitle"),
            }}
          ></h3>
          <video
            src={video1}
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%", // El video ocupará el 100% del contenedor
              maxWidth: "500px", // Limitar el ancho máximo del video
              height: "auto", // Ajustar la altura automáticamente
              maxHeight: "400px", // Limitar la altura máxima del video
              borderRadius: "12px",
              marginTop: "20px",
            }}
          />
        </div>
      ),
      placement: "bottom", // Cambiar a 'bottom' para que aparezca debajo del botón
      styles: {
        options: {
          width: "auto", // Asegurar que el ancho del tooltip se ajuste automáticamente
          maxWidth: "750px", // Limitar el ancho del contenedor del tour
          marginTop: "20px", // Añadir margen superior
          marginBottom: "20px", // Añadir margen inferior
        },
      },
      media: `
      @media (max-width: 1024px) {
        div {
          height: 70vh; /* Reducir la altura en pantallas más pequeñas */
        }
        h3 {
          font-size: 1.3rem;
        }
        img {
          max-width: 50%;
          maxHeight: 20vh; /* Limitar altura en pantallas pequeñas */
        }
        video {
          max-width: 80%;
          max-height: 30vh; /* Reducir la altura del video en pantallas pequeñas */
        }
        p {
          font-size: 1rem;
        }
      }
      @media (max-width: 768px) {
        div {
          height: 60vh;
        }
        h3 {
          font-size: 1.2rem;
        }
        img {
          max-width: 60%;
          maxHeight: 15vh; /* Reducir la altura de la imagen en pantallas más pequeñas */
        }
        video {
          max-width: 90%;
          max-height: 25vh;
        }
        p {
          font-size: 0.9rem;
        }
      }
      @media (max-width: 480px) {
        div {
          height: 50vh;
        }
        h3 {
          font-size: 1rem;
        }
        img {
          max-width: 70%;
          maxHeight: 10vh;
        }
        video {
          max-width: 100%;
          max-height: 20vh;
        }
        p {
          font-size: 0.8rem;
        }
      }
    `,
    },
    {
      target: ".tour-anexos",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t("software.cliente.onboarding.annexSection"),
          }}
        ></div>
      ),
    },
    {
      target: ".tour-responder",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t("software.cliente.onboarding.responseSection"),
          }}
        ></div>
      ),
    },
    {
      target: ".tour-help",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t("software.cliente.onboarding.helpSection"),
          }}
        ></div>
      ),
    },
  ];

  return (
    <div className={styles.editor}>
      {/* Configurar Joyride para el tour */}
      <Joyride
        steps={steps}
        run={runTour} // Ejecutar el tour
        continuous={true}
        scrollToFirstStep={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideCloseButton={true}
        locale={{
          back: t("software.dashboard.onboarding.buttons.back"),
          close: t("software.dashboard.onboarding.buttons.close"),
          last: t("software.dashboard.onboarding.buttons.last"),
          next: t("software.dashboard.onboarding.buttons.next"),
          skip: t("software.dashboard.onboarding.buttons.skip"),
        }}
        styles={{
          options: {
            primaryColor: "#007bff", // Color principal
            zIndex: 6000, // Prioridad en la superposición
          },
          spotlight: {
            borderRadius: "20px", // Bordes redondeados del spotlight
          },
          spotlightLegacy: {
            boxShadow: "none", // Eliminar el box-shadow grande que podría crear espacio visual extra
          },
          tooltipContainer: {
            textAlign: "left", // Alinear texto a la izquierda
          },
          buttonNext: {
            backgroundColor: "#61D1B5", // Color del botón "Continuar"
            borderRadius: "20px", // Bordes redondeados del botón
          },
          buttonBack: {
            color: "#6c757d", // Color del botón "Atrás"
          },
          spotlightPadding: 0, // Sin espacio extra alrededor del componente destacado
        }}
      />
      <DocumentEditorContainerComponent
        ref={editorRef}
        enableToolbar={true}
        toolbarItems={customToolbarItems}
        width="100%"
        height="100%"
        showPropertiesPane={false}
        className="tour-editor"
        locale={i18n.language}
      >
        <Inject services={[Toolbar, WordExport]} />
      </DocumentEditorContainerComponent>

      <Modal
        open={isImporting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "transparent",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Fallback />
        </Box>
      </Modal>

      <Modal
        open={isSaving}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "transparent",
            borderRadius: "8px",

            p: 4,
          }}
        >
          <Fallback />
        </Box>
      </Modal>
      <input
        type="file"
        accept=".doc,.docx,.pdf"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onFileChange}
      />
      <Dialog open={showDialog} onClose={handleCancelNavigation}>
        <DialogTitle sx={{ paddingTop: "60px" }}>
          {t("software.editor.exitMessage2")}
          <IconButton
            aria-label="close"
            onClick={handleCancelNavigation}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveAndProceed}
            color="primary"
            disabled={isSaving}
          >
            {t("software.editor.register&exit")}
          </Button>
          <Button
            onClick={handleProceedWithoutSaving}
            color="inherit"
            disabled={isSaving}
          >
            {t("software.editor.noRegister")}
          </Button>
          <Button
            onClick={handleCancelNavigation}
            color="inherit"
            disabled={isSaving}
          >
            {t("software.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowMessage(false)}
          severity={operationSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {operationSuccess
            ? t("software.alerts.docs.successAlert")
            : t("software.alerts.docs.errorAlert")}
        </Alert>
      </Snackbar>
      {/* Modal para seleccionar el formato de descarga */}
      <Dialog open={openDownloadModal} onClose={handleDownloadModalClose}>
        <DialogTitle>{t("software.editor.download.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("software.editor.download.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ChakraButton
            onClick={downloadDocumentAsDocx}
            size="md"
            colorScheme="teal"
          >
            {t("software.editor.download.docx")}
          </ChakraButton>

          <ChakraButton
            onClick={downloadDocumentAsPdf}
            size="md"
            colorScheme="teal"
          >
            {t("software.editor.download.pdf")}
          </ChakraButton>

          <ChakraButton
            onClick={handleDownloadModalClose}
            size="md"
            variant="outline"
            colorScheme="teal"
            sx={{
              backgroundColor: "white", // Fondo blanco
              color: "black", // Texto negro
              borderColor: "black", // Borde negro
              "&:hover": {
                backgroundColor: "gray.100", // Fondo gris claro en hover
                color: "black", // Mantener texto negro
              },
            }}
          >
            {t("software.cancel")}
          </ChakraButton>
        </DialogActions>
      </Dialog>

      {/* Modal para confirmar la nueva versión */}
      <Dialog open={showRegisterModal} onClose={cancelRegisterVersion}>
        <DialogTitle sx={{ paddingTop: "60px" }}>
          {t("software.editor.registerVersion")}
          <IconButton
            aria-label="close"
            onClick={cancelRegisterVersion}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("software.editor.registerMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmRegisterVersion} color="primary">
            {t("software.editor.register")}
          </Button>
          <Button onClick={cancelRegisterVersion} color="inherit">
            {t("software.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <StepperController
        open={showStepperModal}
        onClose={handleCloseModal}
        emisor={emisor}
        documentKey={documentkey}
        onSend={handleSend}
        encryptedPin={encryptedPin}
        company={company}
        userEmail={usuario}
      />
      <HelpModal
        open={isHelpModalOpen}
        onClose={handleCloseHelpModal}
        onLanguageChange={handleLanguageChange} // Le pasamos la función
        currentLanguage={i18n.language}
        onRepeatTutorial={repeatTutorial}
      />
    </div>
  );
};

//Contenedor del editor
export default function EditorVeriones() {
  const { t } = useTranslation();
  const { search } = useLocation(); // Obtener la query string (que contiene el token)
  const params = new URLSearchParams(search);
  const token = params.get("token"); // Extrae el token de la URL
  const encryptedPin = params.get("encryptedPin"); // Extrae encryptedPin
  const company = params.get("company"); // Extrae company
  const [versionIdSfdt, setVersionIdSfdt] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [emisor, setEmisor] = useState(null);
  const [showAnexosModal, setShowAnexosModal] = useState(false);
  const [documentKey, setDocumentKey] = useState(null);
  const [alias, setAlias] = useState(null);
  const [allowDownload, setAllowDownload] = useState(true); // Por defecto true
  const [sfdtKeyEditable, setSfdtKeyEditable] = useState(null); // Nuevo estado para obtener la version editable de la sfdt document key

  const isClient = true;

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token); // Decodificar el token
      setDocumentKey(decodedToken.document_key); // Establece el documentKey
      setVersionIdSfdt(decodedToken.version_id_sfdt); // Establece el versionId
      setUsuario(decodedToken.destinatario); // Establece el destinatario como usuario
      setEmisor(decodedToken.emisor);
      setAlias(decodedToken.alias_version);
      setAllowDownload(decodedToken.allow_download);
      setSfdtKeyEditable(decodedToken.sfdt_key);
    }
  }, [token]);

  const [documentContent, setDocumentContent] = useState(null);
  const [
    leerVersion,
    { loading: documentLoading, error: documentError, data: documentData },
  ] = useLazyQuery(LEER_VERSION, {
    variables: {
      documentKey,
      versionId: versionIdSfdt,
      sfdtKeyEditable,
      encryptedPin,
      company,
      isClient,
    },
    skip: !documentKey || !versionIdSfdt || !encryptedPin || !company, // Evita ejecutar la query si no hay documentKey o versionId
  });

  // Execute the query using the documentKey prop
  const { error, data } = useQuery(DATOS_DOCUMENTO, {
    variables: { documentKey, userEmail: emisor },
  });
  const tituloContrato =
    data?.datosDocumentocompartido?.titulo || t("software.editor.titleNoDisp");

  // Dentro de Elaboracion1, añade el estado para manejar la visibilidad del modal

  const [showMessage, setShowMessage] = useState(false);
  const [operationSuccess, setOperationSuccess] = useState(true);

  useEffect(() => {
    if (documentKey && versionIdSfdt) {
      leerVersion({
        variables: {
          documentKey,
          versionId: versionIdSfdt,
          sfdtKeyEditable,
          encryptedPin,
          company,
          isClient,
        },
      });
    }
  }, [documentKey, versionIdSfdt, leerVersion, isClient]);

  useEffect(() => {
    if (documentData && documentData.leerVersionCliente) {
      const decodedContent = atob(documentData.leerVersionCliente.content);
      try {
        setDocumentContent(JSON.parse(decodedContent));
      } catch (error) {
        console.error("Error al decodificar el documento:", error);
      }
    }
  }, [documentData]);
  //Contar anexos Callback

  const [anexosCount, setAnexosCount] = useState(0);

  const handleAnexosCount = (count) => {
    setAnexosCount(count);
  };

  // Usa un efecto para activar la carga de anexos cuando se muestre el modal
  const { data: dataquery } = useQuery(OBTENER_ANEXOS, {
    variables: { documentKey },
  });

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexos) {
      const docs = dataquery.obtenerAnexos.map((doc) => ({
        ...doc,
        name: doc.titulo,
        enlace: doc.enlace,
        id: doc.titulo,
      }));

      setAnexosCount(docs.length); // Notifica al componente padre sobre la cantidad de anexos
    }
  }, [dataquery]);

  const docxDocumentKey = documentKey
    ? documentKey.replace(".sfdt", ".docx")
    : null;

  //Query Token Caducado enlace caducado
  // Hacer la consulta de verificación del token_respuesta
  const {
    loading: loadingtoken,
    error: errortoken,
    data: checktokendata,
  } = useQuery(VERIFICAR_TOKEN, {
    variables: { documentKey, tokenRespuesta: token },
    skip: !documentKey || !token, // Saltar si no tenemos documentKey o token
  });

  //Ajuste Fallback

  const [showFallback, setShowFallback] = useState(true);

  const handleLoaded = () => {
    setShowFallback(false);
  };

  if (errortoken) return <p>Error: {error.message}</p>;

  // Verificar si el token es válido
  if (checktokendata && checktokendata.verificarToken.existe) {
    console.log(token);
    return <NotFound />;
  }

  if (documentLoading || loadingtoken || showFallback) {
    return (
      <Fallback
        loading={documentLoading || loadingtoken}
        onLoaded={handleLoaded}
      />
    );
  }

  // Verificar si las variables están disponibles
  if (!versionIdSfdt) {
    return <NotFound />;
  }

  if (documentError) return <p>Error: {documentError.message}</p>;
  if (!documentKey || !versionIdSfdt) {
    return (
      <p>{t("software.profile.upgradePlanModal.upgradeButton.loading")}</p>
    ); // O muestra un fallback adecuado
  }

  return (
    <div className={styles.container}>
      <div className={styles.a4Container}>
        <MyEditor
          titulo={tituloContrato}
          documentContent={documentContent}
          usuario={usuario}
          documentkey={documentKey}
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          operationSuccess={operationSuccess}
          setOperationSuccess={setOperationSuccess}
          setShowAnexosmodal={setShowAnexosModal}
          anexosnumber={anexosCount}
          docxDocumentKey={docxDocumentKey}
          idVersion={versionIdSfdt}
          emisor={emisor}
          token={token}
          encryptedPin={encryptedPin}
          company={company}
          alias={alias}
          allowDownload={allowDownload}
          sfdtKeyEditable={sfdtKeyEditable}
        />
      </div>
      <div className={styles.sidebarMenu}>
        {showAnexosModal && (
          <AnexosModal
            onClose={() => setShowAnexosModal(false)}
            documentKey={documentKey}
            onAnexosCount={handleAnexosCount}
            encryptedPin={encryptedPin}
            company={company}
            userEmail={usuario}
          />
        )}
      </div>
    </div>
  );
}

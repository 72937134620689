import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import video3 from "../../../assets/Animations/Tutorial-Comentarios.mp4";
import video1 from "../../../assets/Animations/Tutorial-Importar.mp4";
import video4 from "../../../assets/Animations/Tutorial-Anexos.mp4";
import video2 from "../../../assets/Animations/Tutorial-Editor.mp4";
import video5 from "../../../assets/Animations/Tutorial-Respuesta.mp4";
import {
  Bs1Circle,
  Bs2Circle,
  Bs3Circle,
  Bs4Circle,
  Bs5Circle,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { RepeatIcon } from "lucide-react";

const HelpModal = ({
  open,
  onClose,
  onLanguageChange,
  currentLanguage,
  onRepeatTutorial,
}) => {
  const { t } = useTranslation();
  const [isVideoExpanded, setVideoExpanded] = useState(null);

  const handleDropdownChange = (newLang) => {
    // Llamamos a la función que nos pasó MyEditor
    onLanguageChange(newLang);
  };

  const handleVideoClick = (videoIndex) => {
    setVideoExpanded(videoIndex);
  };

  const handleCloseVideo = () => {
    setVideoExpanded(null);
  };

  function CustomDropdown({ value, onChange, options }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleOptionClick = (optionValue) => {
      onChange(optionValue);
      setIsOpen(false);
    };

    // Cerrar el dropdown si se hace clic fuera de él
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    const selectedOption = options.find((opt) => opt.value === value);

    return (
      <div
        ref={dropdownRef}
        style={{
          position: "relative",
          fontFamily: "sans-serif",
          fontSize: "14px",
        }}
      >
        <div
          onClick={handleToggle}
          style={{
            backgroundColor: "#fff",
            border: "none",
            padding: "8px 12px",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "100px",
          }}
        >
          <span>{selectedOption ? selectedOption.label : "Select..."}</span>
          <span
            style={{
              display: "inline-block",
              width: "0",
              height: "0",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: isOpen ? "none" : "5px solid #333",
              borderBottom: isOpen ? "5px solid #333" : "none",
              marginLeft: "8px",
            }}
          />
        </div>
        {isOpen && (
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: "8px 0",
              position: "absolute",
              top: "calc(100% + 5px)",
              left: 0,
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              zIndex: 999,
            }}
          >
            {options.map((opt) => (
              <li
                key={opt.value}
                onClick={() => handleOptionClick(opt.value)}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  transition: "background 0.2s ease-in-out",
                  ...(opt.value === value
                    ? { backgroundColor: "#f0f0f0", fontWeight: "bold" }
                    : {}),
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f7f7f7")
                }
                onMouseLeave={(e) => {
                  if (opt.value === value) {
                    e.currentTarget.style.backgroundColor = "#f0f0f0";
                  } else {
                    e.currentTarget.style.backgroundColor = "#fff";
                  }
                }}
              >
                {opt.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  const sections = [
    {
      id: 1,
      icon: <Bs1Circle />,
      title: t("software.cliente.helpCliente.editorTitle"),
      text: t("software.cliente.helpCliente.editorText"),
      video: video2,
    },
    {
      id: 2,
      icon: <Bs2Circle />,
      title: t("software.cliente.helpCliente.commentsTitle"),
      text: t("software.cliente.helpCliente.commentsText"),
      video: video3,
    },
    {
      id: 3,
      icon: <Bs3Circle />,
      title: t("software.cliente.helpCliente.importTitle"),
      text: t("software.cliente.helpCliente.importText"),
      video: video1,
    },
    {
      id: 4,
      icon: <Bs4Circle />,
      title: t("software.cliente.helpCliente.annexesTitle"),
      text: t("software.cliente.helpCliente.annexesText"),
      video: video4,
    },
    {
      id: 5,
      icon: <Bs5Circle />,
      title: t("software.cliente.helpCliente.responseTitle"),
      text: t("software.cliente.helpCliente.responseText"),
      video: video5,
    },
  ];

  const options = [
    { value: "es", label: t("language.spanish") },
    { value: "en", label: t("language.english") },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            fontSize: "1.8rem",
            fontWeight: "bold",
          }}
        >
          {/* Contenedor para gestionar el título y el selector */}
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
              }}
            >
              {t("software.cliente.helpCliente.title")}
            </Typography>

            {/* BOTÓN REPETIR TUTORIAL */}
            <Button
              onClick={onRepeatTutorial}
              sx={{
                backgroundColor: "#fff",
                border: "none",
                padding: "8px 12px",
                borderRadius: "5px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100px",
                color: "#000",
                marginRight: "16px", // si quieres mantener ese espacio
                textTransform: "none", // para que no aparezca en mayúsculas
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <RepeatIcon size={16} style={{ marginRight: "8px" }} />
              {t("software.cliente.helpCliente.repeatTutorial")}
            </Button>

            {/* Selector de idioma */}
            <CustomDropdown
              value={currentLanguage}
              onChange={handleDropdownChange}
              options={options}
            />
          </div>

          {/* Botón de cerrar */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "16px", // Espaciado entre el selector y la X
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            overflowY: "auto",
            padding: "20px",
          }}
        >
          {sections.map((section) => (
            <div key={section.id} style={{ marginBottom: "40px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {React.cloneElement(section.icon, {
                      size: "1.5rem",
                      style: { verticalAlign: "middle" },
                    })}
                    <span style={{ paddingLeft: "10px" }}>{section.title}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ paddingLeft: "30px", marginTop: "10px" }}
                  >
                    {section.text}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      position: "relative",
                      transition: "transform 0.3s ease-in-out",
                      marginLeft: "10px",
                    }}
                  >
                    <video
                      src={section.video}
                      autoPlay
                      loop
                      muted
                      playsInline
                      onClick={() => handleVideoClick(section.id)}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        transition: "transform 0.3s ease-in-out",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "scale(1)";
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </DialogContent>
      </Dialog>

      {isVideoExpanded && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <video
            src={sections.find((s) => s.id === isVideoExpanded)?.video}
            autoPlay
            loop
            muted
            playsInline
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              borderRadius: "8px",
            }}
          />
          <IconButton
            onClick={handleCloseVideo}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              fontSize: "2rem",
              zIndex: 10000,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default HelpModal;

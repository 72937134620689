import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  LinearProgress,
  IconButton,
  Alert,
} from "@mui/material";
import { IoSendSharp } from "react-icons/io5";
import { Close as CloseIcon } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import { useTranslation } from "react-i18next";
import { Button as ChakraButton } from "@chakra-ui/react";

const steps = 3;

const StepperController = ({
  open,
  onClose,
  onSend,
  emisor,
  documentKey,
  encryptedPin,
  company,
  userEmail,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [metaData, setMetaData] = useState({
    step1: null,
    step2: null,
    step3: null,
  });

  // Inicializar emailData con valores por defecto
  const [emailData, setEmailData] = useState({
    to: emisor || "",
    subject: "",
    message: "",
  });

  const [showWarning, setShowWarning] = useState(false);
  const [direction, setDirection] = useState(1); // 1 para next, -1 para back

  const step2Ref = useRef(null); // Referencia para Content2

  useEffect(() => {
    if (!open) {
      // Solo reiniciar cuando se cierre el modal
      setActiveStep(0);
      setMetaData({
        step1: null,
        step2: null,
        step3: null,
      });
      setEmailData({
        to: emisor || "",
        subject: "",
        message: "",
      });
      setShowWarning(false); // Resetear el aviso
    }
  }, [open, emisor]);

  // Manejar cambios en los campos de correo electrónico
  const handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    setEmailData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleNext = () => {
    setDirection(1);
    if (activeStep < steps - 1) {
      if (activeStep === 1) {
        const anexosData = step2Ref.current.getAnexoData();
        setMetaData((prev) => ({
          ...prev,
          step2: anexosData,
        }));
      }
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setDirection(-1);
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleFinish = () => {
    const conformidad = metaData.step1; // `metaData.step1` ya es `true` o `false`
    // Guardar los datos del email antes de enviar
    setMetaData((prev) => ({
      ...prev,
      step3: { ...emailData }, // Guardar el emailData correctamente en la metadata
    }));

    // Pasar la metadata completa al backend y cerrar el modal
    onSend({ ...metaData, step3: { ...emailData }, conformidad });
    onClose();
  };

  // Manejar la respuesta de Content1
  const handleAnswer = (answer) => {
    setMetaData((prev) => ({
      ...prev,
      step1: answer, // Ahora será `true` o `false`
    }));
    handleNext(); // Avanzar automáticamente
  };

  // Validar que el asunto y el mensaje estén completos
  const isFormValid =
    emailData.subject.trim() !== "" && emailData.message.trim() !== "";

  const isLastStep = activeStep === steps - 1;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "80vw",
          minHeight: "90%",
          maxHeight: "95vh",
          margin: "auto",
          mt: "2rem",
          p: 3,
          backgroundColor: "white",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{ flexGrow: 1, mb: 2, position: "relative", overflow: "hidden" }}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={activeStep}
              custom={direction}
              variants={{
                enter: (direction) => ({
                  x: direction === 1 ? "100%" : "-100%",
                  opacity: 0,
                }),
                center: { x: 0, opacity: 1 },
                exit: (direction) => ({
                  x: direction === 1 ? "-100%" : "100%",
                  opacity: 0,
                }),
              }}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "tween", duration: 0.5, ease: "easeInOut" },
                opacity: { duration: 0.2 },
              }}
              style={{ position: "absolute", width: "100%" }}
            >
              {activeStep === 0 && (
                <Content1 onAnswer={handleAnswer} emisor={emisor} />
              )}
              {activeStep === 1 && (
                <Content2
                  ref={step2Ref}
                  documentKey={documentKey}
                  encryptedPin={encryptedPin}
                  company={company}
                  userEmail={userEmail}
                />
              )}
              {activeStep === 2 && (
                <Content3
                  emisor={emisor}
                  emailData={emailData}
                  handleInputChange={handleInputChange}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </Box>

        {showWarning && (
          <Alert
            severity="warning"
            onClose={() => setShowWarning(false)}
            sx={{ mb: 2 }}
          >
            {t("software.cliente.stepperModalCliente.warning")}
          </Alert>
        )}

        <Box sx={{ mt: "auto" }}>
          <LinearProgress
            variant="determinate"
            value={(activeStep / (steps - 1)) * 100}
            sx={{
              height: "8px",
              borderRadius: "5px",
              mb: 1,
              backgroundColor: "#e0e0e0",
              "& .MuiLinearProgress-bar": {
                background: `linear-gradient(90deg, #1976d2, #61D1B5)`,
                borderRadius: "5px",
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {activeStep > 0 && (
              <ChakraButton
                onClick={handleBack}
                size="md"
                colorScheme="teal"
                disabled={activeStep === 0}
              >
                {t("software.cliente.stepperModalCliente.back")}
              </ChakraButton>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep !== 0 && !isLastStep && (
              <ChakraButton onClick={handleNext}>
                {t("software.cliente.stepperModalCliente.next")}
              </ChakraButton>
            )}
            {isLastStep && (
              <Button
                onClick={
                  isFormValid ? handleFinish : () => setShowWarning(true)
                }
                sx={{
                  py: 0.8,
                  fontSize: "1rem",
                  borderRadius: "10px",
                  textTransform: "none",
                  background: isFormValid
                    ? "#4BBF9D"
                    : `linear-gradient(90deg,#CCCCCC, #E0E0E0)`,
                  borderColor: isFormValid ? "#007BFF" : "#cccccc",
                  color: isFormValid ? "#fff" : "#888",
                  cursor: isFormValid ? "pointer" : "not-allowed",
                  "&:hover": isFormValid
                    ? {
                        background: "#4BBF9D",
                      }
                    : {},
                }}
              >
                {t("software.cliente.stepperModalCliente.send")}
                <IoSendSharp style={{ marginLeft: "10px" }} />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StepperController;
